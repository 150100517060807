const shuffle_object = (obj) => {
  const entries = Object.entries(obj);

  for (let i = entries.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [entries[i], entries[j]] = [entries[j], entries[i]];
  }

  return Object.fromEntries(entries);
};

const shuffle = (list) => {
  for (let i = 0; i < list.length - 1; i++) {
    const j = i + Math.floor(Math.random() * (list.length - i));

    const temp = list[j];
    list[j] = list[i];
    list[i] = temp;
  }

  return list;
};

export { shuffle };
