import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GENERIC, BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { Flex, Container } from 'components/grids/v3';
import { HContent, VContainer, VContent, CContainer, Button } from 'components';
import { Header } from '../header/Header';
import { GItems } from './GItems';

import './Home.scss';

const Home = () => {
  const history = useHistory();
  const { account } = useSelector((state) => state.auth);

  const handle_select = () => {
    history.push(GENERIC.APP_BASE_URL);
  };

  return (
    <Screen background={BACKGROUND}>
      <Flex direction={Flex.V}>
        <Flex.Scrap>
          <Header />
          <div className="home-title app-bg">
            {
              account && (
                <div>
                  <CContainer>
                    <Button label={GENERIC.APP_FULL_NAME} icon="checkmark" onClick={handle_select} />
                  </CContainer>
                </div>
              )
            }
          </div>
          <div className="home-title">
            <h1>{GENERIC.APP_FULL_NAME}</h1>
            <p>
              Bienvenido a un producto de UnBoliviano un espacio dedicado a las
              Bandas Estudiantiles de Bolivia
            </p>
          </div>
          <GItems />
        </Flex.Scrap>
      </Flex>
    </Screen>
  );
};

export { Home };
