import { GENERIC } from 'generics/constants';

export const URI = {
  get: '/v1/schools',
  get_by_id: '/v1/schools/{_id}',
  post: '/v1/schools',
  put: '/v1/schools/{_id}',
  remove: '/v1/schools/{_id}',
};
export const ROUTE = {
  list: `${GENERIC.APP_BASE_URL}/schools`,
  new: 'schools/new',
};
export const CONTEXT = 'Colegios';
export const TITLE = 'lista de colegios';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'area de creacion y edicion de colegios',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'lista de colegios',
};
export const BUTTON_LABELS = {
  NEW: 'Crear colegio',
  REMOVE: 'Eliminar colegio',
  CREATE: 'Crear colegio',
  UPDATE: 'Actualizar colegio',
};
export const DEFAULT_STOCK = {
  price: 0,
  amount: 0,
  min: 10,
  max: 100,
};
