import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { BACKGROUND } from 'generics/constants';
import { Session } from '../session/Session';

import { Screen } from 'components/screens/v2';
import { HContent, VContainer, VContent, CContainer, Button } from 'components';
import { Menu } from './Menu';
import { CheckoutHistory } from './checkout-history/CheckoutHistory';
import { Bandas } from 'app/bandas/Bandas';

const Market = () => {
  const [item, set_item] = useState(null);
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);

  useEffect(() => {
    set_item(area);
  }, [area]);

  const render_markets = () => (
    <Switch>
      <Route exact path={`${path}/history`}>
        <Bandas area={area} />
      </Route>
    </Switch>
  );

  return (
    <Screen background={BACKGROUND}>
      {/* <IconToolbarContainer> */}
        {/* <Session />
        <Menu /> */}
      {/* </IconToolbarContainer> */}
      <VContent>
        {/* asdasd */}
        {/* {area && render_markets()} */}
        {render_markets()}
      </VContent>
    </Screen>
  );
};

export { Market };
