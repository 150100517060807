import React from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import { BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { Flex, Container } from 'components/grids/v3';
import { IButton } from 'components/buttons/v2';

import { GItem } from './GItem';
import { GItems } from './GItems';

import { URI, ROUTE } from './constants';

const GRoute = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const on_return = () => {
    history.push('/');
  };

  return (
    <Screen background={BACKGROUND}>
      <Flex direction={Flex.V}>
        <Flex.Scrap>
          <Flex direction={Flex.H}>
            <IButton label="Volver" icon="checkmark" on_click={on_return} />
          </Flex>
        </Flex.Scrap>
        <Switch>
          <Route exact path={path}>
            <Flex.Item>
              <GItems route={ROUTE} uri={URI} />
            </Flex.Item>
          </Route>
          <Route exact path={`${path}/new`}>
            <Flex.Item>
              <Flex direction={Flex.V}>
                <Container width="350px">
                  <GItem route={ROUTE} uri={URI} />
                </Container>
              </Flex>
            </Flex.Item>
          </Route>
          <Route exact path={`${path}/:_id`}>
            {/* <GItem route={ROUTE} uri={URI} /> */}
          </Route>
        </Switch>
      </Flex>
    </Screen>
  );
};

GRoute.propTypes = {};

export { GRoute };
