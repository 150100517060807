import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { BUTTON_LABELS, RemoveConfirmation } from 'components/generics/v1';
import { MultiSelect } from 'components/forms/v2';
import { toast } from 'generics/services/globals/v4';
import { ContentTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v4';
import { useFormReducer } from 'generics/functions/forms/v2';
import { UImage } from 'components/images/v3';
import { IButton } from 'components/buttons/v2';

import { service } from './services';
import { ITEM_HEADER, ROUTE } from './constants';

const GItem = ({ area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const [is_new] = useState(_id === undefined);
  const {
    item,
    set_item,
    on_string_change,
    on_image_change,
  } = useFormReducer({ schools: [] });

  const create = () => {
    service.create(item)
    .then(() => {
      history.push(ROUTE.LIST);
    })
    .catch(toast.api_danger);
  };

  const update = () => {
    service.update(_id, item)
    .then(() => {
      history.push(ROUTE.LIST);
    })
    .catch(toast.api_danger);
  };

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      create();
    } else {
      update();
    }
  };

  const on_remove_callback = useCallback(() => {
    service.remove(_id)
    .then(() => {
      history.push(ROUTE.LIST);
    })
    .catch(toast.api_danger);
  }, [history, _id]);

  const on_schools_change = (_ids) => {
    set_item({ ...item, schools: _ids });
  };

  useEffect(() => {
    if (is_new) return;

    service.get_by_id(_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, _id, set_item]);

  const render_buttons = () => {
    if (is_new) {
      return <IButton label={BUTTON_LABELS.CREATE} icon="checkmark" submit />;
    }

    return (
      <>
        <RemoveConfirmation label={BUTTON_LABELS.REMOVE} on_click={on_remove_callback} />
        <IButton label={BUTTON_LABELS.UPDATE} icon="checkmark" submit />
      </>
    );
  };

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre del almacen" value={item.name} onChange={on_string_change} />
        </Form.Field>
        {/* <Form.Field>
          <MultiSelect selector="name" placeholder="Colegios" path="/v1/schools" params={{ area_id: area._id }} _ids={item.schools} on_select={on_schools_change}>
            <label>Colegios</label>
          </MultiSelect>
        </Form.Field> */}
        <Form.Field>
          <label>Descripción</label>
          <textarea name="address" placeholder="Direccion" value={item.address} onChange={on_string_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" on_callback={on_image_change} />
        <Flex.End>
          {render_buttons()}
        </Flex.End>
      </Form>
    </>
  );
};

GItem.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItem };
