import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/images/v3';

import './CardListItem.scss';

const CardListItem = ({ item, convert, on_select }) => {
  const [data, setData] = useState(convert(item));
  const on_click = (e) => {
    e.stopPropagation();
    on_select(item);
  };

  useEffect(() => {
    setData(convert(item));
  }, [item, convert]);

  return (
    <div className="list-item" onClick={on_click}>
      <div className="image">
        <Image image={data.image_id} direction={Image.H} height="160px" quality={240} />
      </div>
      <div className="content">
        <div className="content-fixed">
          <div className="detail-title">
            <div className="title">{data.name}</div>
            <div className="subtitle">{data.description}</div>
          </div>
          <div className="detail-detail">
            <div className="detail-item">
              <div className="title-detail">CIUDAD</div>
              <div className="title-value">{data.city}</div>
            </div>
            <div className="detail-item">
              <div className="title-detail">ANIVERSARIO</div>
              <div className="title-value">{data.anniversary}</div>
            </div>
            <div className="detail-item">
              <div className="title-detail">INTEGRANTES</div>
              <div className="title-value">{data.quantity}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="image">
        <Image image={data.band_id} direction={Image.H} height="160px" quality={240} />
      </div>
    </div>
  );
};

CardListItem.propTypes = {
  item: PropTypes.any.isRequired,
  convert: PropTypes.func.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { CardListItem };
