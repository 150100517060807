import bookings from './bookings.service';
import control_codes from './control-codes.service';
import invoices from './invoices.service';
import orders from './orders.service';
import sales from './sales.service';
import settings from './settings.service';

export default {
  bookings,
  control_codes,
  invoices,
  orders,
  sales,
  settings,
};
