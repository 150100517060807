import React, { useCallback, useEffect, useState } from 'react';

import { api, toast } from 'generics/services/globals/v3';
import { useHistory } from 'react-router-dom';
import { Flex } from 'components/grids/v2';
import { CardList } from './card/CardList';

const GItems = () => {
  const history = useHistory();
  const [items, set_items] = useState([]);

  const on_create = useCallback(() => {
    // history.push(route.new);
  }, [history]);

  const on_select = useCallback((item) => {
    // history.push(`${route.list}/${item._id}`);
  }, [history]);

  const convert = (item) => ({
    image_id: item.is_approve ? item.image_id : null || null,
    band_id: item.is_approve ? item.band_id : null || null,
    name: item.name || 'Not found',
    quantity: item.quantity || 'Not found',
    city: item.city || 'Not found',
    anniversary: item.anniversary || 'Not found',
    description: item.description || 'Not found',
  });

  useEffect(() => {
    api.get('/v1/schools', null, {})
    .then(({ data }) => {
      set_items(data.sort((a, b) => {
        a.quantity = a.quantity ? a.quantity : 0;
        b.quantity = b.quantity ? b.quantity : 0;
        return a.quantity + b.quantity;
      }));

      console.log(data);
      
    })
    .catch(toast.api_danger);
  }, []);

  return (
    <CardList items={items} convert={convert} on_select={on_select} />
  );
};

GItems.propTypes = {};

export { GItems };
