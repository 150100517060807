import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { Flex } from 'components/grids/v4';
import { Session } from 'app/session/Session';

import { AccountRoutes } from 'app/features/accounts/AccountRoutes';
import { SettingRoutes } from 'app/features/settings/SettingRoutes';
import { BillingRoutes } from 'app/features/billings/BillingRoutes';
import { ConfigurationRoutes } from 'app/features/configurations/ConfigurationRoutes';

import { Header } from '../header/Header';
import { Menu } from '../header/Menu';

import { GRoute as MainsRoute } from './main/GRoute';
import { GRoute as SchoolsRoute } from './schools/GRoute';
import { GRoute as EventsRoute } from './events/GRoute';

const Sales = () => {
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);

  const render_sales = () => (
    <Flex.Item>
      <Switch>
        <Route path={`${path}/schools`}>
          <SchoolsRoute area={area} />
        </Route>
        <Route path={`${path}/events`}>
          <EventsRoute area={area} />
        </Route>
        <Route path={`${path}`}>
          <MainsRoute area={area} />
        </Route>
      </Switch>
      <AccountRoutes />
      <SettingRoutes />
      <BillingRoutes />
      <ConfigurationRoutes />
    </Flex.Item>
  );

  return (
    <Screen background={BACKGROUND}>
      <Session />
      <Menu />
      <Flex.Item>
        <Flex direction={Flex.V}>
          <Header />
          {area && render_sales()}
        </Flex>
      </Flex.Item>
    </Screen>
  );
};

export { Sales };
