import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import api from 'generics/api/billings/v1';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { toast } from 'generics/services/globals/v4';
import { IButton, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../setting.constants';

import './SettingControlCode.scss';

const SettingControlCode = ({ area }) => {
  const {
    item,
    set_item,
    on_string_change,
  } = useFormReducer({});
  const [control_code_text, set_control_code_text] = useState(null);

  const handle_submit = (e) => {
    e.preventDefault();
    api.control_codes.test(item)
    .then(({ data }) => {
      set_control_code_text(data.control_code_text);
      toast.success('El test fue exitoso');
    })
    .catch(toast.api_danger);
  };

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item({
        area_id: area._id,
        invoice_authorization_number: data.invoice_authorization_number,
        invoice_dosage_key: data.invoice_dosage_key,
      });
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Numero de autorización</label>
          <input name="invoice_authorization_number" placeholder="Numero de autorización" value={item.invoice_authorization_number} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Codigo de dosificación</label>
          <input name="invoice_dosage_key" placeholder="Codigo de dosificación" value={item.invoice_dosage_key} onChange={on_string_change} />
        </Form.Field>
        <div className="control-code-text">
          {control_code_text}
        </div>
        <Form.Field>
          <label>Numero de factura</label>
          <input name="invoice_counter" placeholder="Numero de factura" value={item.invoice_counter} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>CI/NIT</label>
          <input name="customer_index" placeholder="CI/NIT" value={item.customer_index} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Fecha</label>
          <input name="date" placeholder="Fecha" value={item.date} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Total</label>
          <input name="total" placeholder="Total" value={item.total} onChange={on_string_change} />
        </Form.Field>
        <IButton label="Testear codigo de control" icon="checkmark" submit />
      </Form>
    </>
  );
};

SettingControlCode.propTypes = {
  area: PropTypes.any.isRequired,
};

export { SettingControlCode };
