import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v2';
import { toast } from 'generics/services/globals/v4';
import services from 'generics/api/toks';
import { GItems } from './GItems';

import './blitch.scss';

const Bandas = ({ area }) => {
  const [schools, set_schools] = useState([]);

  useEffect(() => {
    // services.schools.find({ area_id: area._id })
    services.events.find({})
    .then(({ data }) => {
      const event = data.find((item) => item.name === 'Todos');
      // const event = data[0];
      if (!event) {
        throw new Error('No event were found');
      }

      return services.schools.find({
        categories: event._id,
      });
    })
    .then(({ data: items }) => {
      set_schools(items);
    })
    .catch(toast.api_danger);
  }, [area]);

  return (
    <Flex direction={Flex.V}>
      <Flex.Item>
        <div className="tournament">
          <GItems area={area} schools={schools} />
        </div>
      </Flex.Item>
    </Flex>
  );
};

Bandas.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { Bandas };
