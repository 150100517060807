import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import api from 'generics/api/billings/v1';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { toast } from 'generics/services/globals/v4';
import { IButton, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../setting.constants';

const CONSUMER_RIGHT = [
  'Ley N° 453:',
  'El proveedor debe brindar atención sin',
  'discriminación, con respecto, calidez y cordialidad',
  'a los usuarios y consumidores',
].join(' ');

const SettingInvoice = ({ area }) => {
  const {
    item,
    set_item,
    on_string_change,
    on_number_change,
  } = useFormReducer({});

  const handle_submit = (e) => {
    e.preventDefault();
    api.settings.update(area._id, item)
    .then(() => {
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  const render_emision_deadline = () => {
    if (!item.invoice_emission_deadline) {
      return null;
    }

    const on_date_change = (element) => {
      set_item({ ...item, invoice_emission_deadline: moment(element.target.value) });
    };

    return (
      <Form.Field>
        <label>Fecha limite de emision</label>
        <input type="date" value={item.invoice_emission_deadline.format('YYYY-MM-DD')} onChange={on_date_change} />
      </Form.Field>
    );
  };

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item({
        invoice_authorization_number: data.invoice_authorization_number || 'AUTH000000',
        invoice_dosage_key: data.invoice_dosage_key || 'DOSAGE000000',
        invoice_counter: data.invoice_counter || 1000,
        invoice_min: data.invoice_min || 1000,
        invoice_max: data.invoice_max || 9000,
        invoice_emission_deadline: data.invoice_emission_deadline ? moment(data.invoice_emission_deadline) : moment().startOf('day'),
        invoice_consumer_right: data.invoice_consumer_right || CONSUMER_RIGHT,
      });
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Numero de autorización</label>
          <input name="invoice_authorization_number" placeholder="Numero de autorización" value={item.invoice_authorization_number} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Codigo de dosificación</label>
          <input name="invoice_dosage_key" placeholder="Codigo de dosificación" value={item.invoice_dosage_key} onChange={on_string_change} />
        </Form.Field>
        {render_emision_deadline()}
        <br />
        <br />
        <Form.Field>
          <label>Contador factura</label>
          <input name="invoice_counter" type="number" placeholder="Contador factura" value={item.invoice_counter} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Numero de factura inicial</label>
          <input name="invoice_min" type="number" placeholder="Numero de factura inicial" value={item.invoice_min} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Numero de factura limite</label>
          <input name="invoice_max" type="number" placeholder="Numero de factura limite" value={item.invoice_max} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Dereho del consumidor</label>
          <input name="invoice_consumer_right" placeholder="Dereho del consumidor" value={item.invoice_consumer_right} onChange={on_string_change} />
        </Form.Field>
        <IButton label="Actualizar información" icon="checkmark" submit />
      </Form>
    </>
  );
};

SettingInvoice.propTypes = {
  area: PropTypes.any.isRequired,
};

export { SettingInvoice };
