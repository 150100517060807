const setting = {
  location: {
    width: 10,
    height: 10,
  },
  box: {
    width: 50,
    height: 100,
    image: null,
  },
};

export const school_template = {
  name: 'LOREM',
  year: 2024,
  image: null,
};

export const generator = {
  data: school_template,
  orientation: 'C',
  location: {
    top: 0,
    left: 0,
    width: setting.location.width,
    height: setting.location.height,
  },
  left: {
    orientation: 'L',
    // data: { ...school_template },
    data: null,
    child: null,
    location: {
      ...setting.box,
      left: 0,
      top: 0,
    },
  },
  right: {
    orientation: 'R',
    // data: { ...school_template },
    data: null,
    child: null,
    location: {
      ...setting.box,
      left: 50,
      top: 0,
    },
  },
};

const init = JSON.parse(JSON.stringify(generator));
// init.location.width = 15;
// init.location.height = 15;
// init.location.top = 50;
// init.location.left = 42.5;
init.location.width = 20;
init.location.height = 20;
init.location.top = 50;
init.location.left = 40;

init.left.child = JSON.parse(JSON.stringify(generator));
init.right.child = JSON.parse(JSON.stringify(generator));
init.left.child.parent = init;
init.right.child.parent = init;
init.left.child.orientation = 'L';
init.right.child.orientation = 'R';

// init.left.child.parent = data.left;
// init.left.child.parent = data.left;

// init.left.parent = init.left;
// init.right.parent = init.left;

init.left.child.left.parent = init.left;
init.left.child.right.parent = init.left;
init.right.child.left.parent = init.right;
init.right.child.right.parent = init.right;

// init.left.child.par

// data.parent.data = data.data;

// init.right.location.left = undefined;
// init.right.location.right = 0;
// init.right.location.top = 0;

init.left.child.location = {
  width: setting.location.width, height: setting.location.height, top: 45, left: 30,
};
init.right.child.location = {
  width: setting.location.width, height: setting.location.height, top: 45, left: 60,
};

export { init };
