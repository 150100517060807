import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// import { Flex } from 'components/grids/v2';
import { Image } from 'components/images/v3';
import { ListItem } from './ListItem';
import { init, generator } from './setting';
import { shuffle } from './shuffle';

const GItems = ({ schools }) => {
  const history = useHistory();
  const [level, set_level] = useState(3);
  const [page, set_page] = useState(null);
  const [lvlA, set_lvl_alpha] = useState([]);
  const [current, set_current] = useState([]);
  const [lvlO, set_lvl_omega] = useState([]);

  const [champion, set_champion] = useState(null);

  const [setting] = useState({
    box: {
      width: 100,
      height: 100,
      image: null,
    },
  });

  const on_select = () => {
    set_lvl_omega([...lvlO]);
  };
  const on_champion = (data) => {
    set_lvl_omega([...lvlO]);
    set_champion(data.data);
  };

  const render_item = (item, index) => (
    <ListItem key={index} item={item} page={page} on_select={on_select} on_champion={on_champion} />
  );

  const generate_tree = (lvl, alpha, schs) => {
    let current_list = [alpha.left.child, alpha.right.child];
    let omega_list = [alpha, ...current_list];

    let count = 2;
    while (count < lvl) {
      const aux = count;
      const new_level = [];

      current_list.forEach((data) => {
        const left = JSON.parse(JSON.stringify(generator));
        const right = JSON.parse(JSON.stringify(generator));
        left.parent = data;
        right.parent = data;
        data.left.child = left;
        data.right.child = right;

        left.left.parent = data.left;
        left.right.parent = data.left;
        right.left.parent = data.right;
        right.right.parent = data.right;

        left.orientation = data.orientation;
        right.orientation = data.orientation;

        if (data.orientation === 'L') {
          left.location.left = data.location.left - 10;
          right.location.left = data.location.left - 10;
          left.location.top = data.location.top - (10 * (lvl - aux));
          right.location.top = data.location.top + (10 * (lvl - aux));
        }

        if (data.orientation === 'R') { // not confirmed
          right.location.left = data.location.left + 10;
          left.location.left = data.location.left + 10;

          left.location.top = data.location.top - (10 * (lvl - aux));
          right.location.top = data.location.top + (10 * (lvl - aux));
        }

        new_level.push(left);
        new_level.push(right);
      });

      current_list = new_level;
      omega_list = [...omega_list, ...new_level];
      count += 1;
    }

    const total_amount = current_list.length * 2;
    const templates = [...schs];
    const diff_total = total_amount - templates.length;

    let count_i = 0;
    while (count_i < diff_total) {
      templates.push(null);
      count_i += 1;
    }
    const temps = shuffle(templates);

    const new_current_list = current_list.map((cur) => {
      const new_copy = { ...cur };
      if (temps.length) {
        new_copy.left.data = temps.shift();
      }
      if (temps.length) {
        new_copy.right.data = temps.shift();
      }

      return new_copy;
    });

    set_lvl_alpha([alpha]);
    set_current([...new_current_list]);
    set_lvl_omega([...omega_list]);
  };

  const on_home = () => {
    history.push('/');
  };

  const on_schools = () => {
    history.push('/schools');
  };

  useEffect(() => {
    // const shuffle_schools = shuffle(schools);
    generate_tree(4, init, schools);

    const element = document.getElementsByClassName('tor-back')[0];
    set_page({
      width: element.offsetWidth,
      height: element.offsetHeight,
    });
  }, [schools]);

  const render_champion = () => (
    <div className="tor-item-champion">
      <div className="champeon">
        <div className="image-title">
          CAMPEÓN
        </div>
        <div className="image-cup" />
        <div className="image-school">
          <Image image={champion.image_id} direction={Image.H} width="100%" height="100%" quality={1080} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="tor-back">
      <div className="tor-item-links">
        <div className="links">
          <div onClick={on_home}>principal</div>
        </div>
        <div className="links">
          <div onClick={on_schools}>my colegio</div>
        </div>
      </div>
      { champion && render_champion() }
      {page !== null && lvlO.map(render_item)}
    </div>
  );
};

GItems.propTypes = {
  // area: PropTypes.shape({
  //   _id: PropTypes.string,
  // }).isRequired,
  schools: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export { GItems };
