import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BASE_PATH } from 'generics/constants';
import default_image from '../../../assets/images/waiting.png';
// import default_image from '../../../assets/images/pexels-photo-1714208.jpeg';

import './Image.scss';

const DIRECTION = {
  H: 'width',
  V: 'height',
};

const Image = ({ image, width, height, direction, quality }) => {
  const [item, setItem] = useState({
    image: default_image,
  });
  const style = {
    width,
    height,
  };

  useEffect(() => {
    setItem({
      image: image ? `${BASE_PATH.FILES}/v1/assets/images/${direction}/${quality}/${image}` : default_image,
    });
  }, [image, quality, direction]);

  return (
    <div className="component-image" style={style}>
      {
        item && <img alt="" src={item.image} />
      }
    </div>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.string,
  quality: PropTypes.number.isRequired,
};

Image.defaultProps = {
  image: null,
  width: 'auto',
  height: 'auto',
  direction: DIRECTION.H,
};

Image.H = DIRECTION.H;
Image.V = DIRECTION.V;

export { Image };
