import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { api, toast } from 'generics/services/globals/v3';
import { ContentTitle } from 'components/titles/v2';
import { IButton } from 'components/buttons/v2';
import { useHistory } from 'react-router-dom';
import { Flex } from 'components/grids/v2';
import { CardList } from 'components/lists/v1';
import { ITEMS_HEADER, BUTTON_LABELS } from './constants';

const GItems = ({ route, uri, area }) => {
  const history = useHistory();
  const [items, set_items] = useState([]);

  const on_create = useCallback(() => {
    history.push(route.new);
  }, [route, history]);

  const on_select = useCallback((item) => {
    history.push(`${route.list}/${item._id}`);
  }, [route, history]);

  const convert = (item) => ({
    image_id: item.is_approve ? item.image_id : null || null,
    name: item.name || 'Not found',
    description: item.description || 'Not found',
  });

  useEffect(() => {
    api.get(uri.get, null, {})
    .then(({ data }) => {
      set_items(data);
    })
    .catch(toast.api_danger);
  }, [uri, area]);

  return (
    <Flex direction={Flex.V}>
      <ContentTitle config={ITEMS_HEADER} />
      <Flex.Item>
        <Flex.End>
          <IButton label={BUTTON_LABELS.NEW} icon="plus" on_click={on_create} />
        </Flex.End>
        <CardList items={items} convert={convert} on_select={on_select} />
      </Flex.Item>
    </Flex>
  );
};

GItems.propTypes = {
  route: PropTypes.shape({
    new: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
  }).isRequired,
  uri: PropTypes.any.isRequired,
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItems };
